import { defineStore } from 'pinia'
import { ref } from 'vue'

export const usePageStore = defineStore('page', () => {
  const isDesktop = ref(window.innerWidth > 1024)
  const isMobile = ref(window.innerWidth < 481)

  const handleResize = () => {
    isDesktop.value = window.innerWidth > 1024
    isMobile.value = window.innerWidth < 481
  }

  return {
    isDesktop,
    isMobile,
    handleResize
  }
})
