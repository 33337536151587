<script setup>
import { ref, provide } from 'vue'
import TopBar from '@/components/atoms/TopBar.vue'

const prevClicked = ref(false)

const handlePrevClicked = () => {
  prevClicked.value = !prevClicked.value
}
provide('prevClicked', prevClicked)
</script>

<template>
  <div class="main-layout">
    <TopBar :canGoBack="true" @prevClicked="handlePrevClicked"></TopBar>
    <RouterView />
  </div>
</template>

<style scoped>
.main-layout {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--beige-bg);
  padding-bottom: 64px;
  overflow: hidden;
}

@supports (height: 100dvh) {
  .main-layout {
    height: 100dvh;
  }
}

@media screen and (max-width: 480px) {
  .main-layout {
    padding: 24px 16px;
    min-height: 100dvh;
    overflow: auto;
  }
}
</style>
