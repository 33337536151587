<script setup>
import { ref, onBeforeMount } from 'vue'
import { sendEvent } from '@/helpers/cerebro'
import { usePageStore } from '@/store/page'
import { storeToRefs } from 'pinia'
import { getLanguage } from '@/helpers/page'

const props = defineProps(['type', 'customLink'])
const pageStore = usePageStore()
const { isDesktop } = storeToRefs(pageStore)

const languageCode = getLanguage()

const link = ref('')

onBeforeMount(() => {
  switch (props.type) {
    case 'privacy':
      link.value = `/privacy-policy-${languageCode}.html`
      break
    case 'terms':
      link.value = `/terms-${languageCode}.html`
      break
    case 'community_guideline':
      link.value = `/community-guidelines-${languageCode}.html`
      break
    case 'refund':
      link.value = `/refund-${languageCode}.html`
      break
    case 'cookie':
      link.value = `/cookie-policy-${languageCode}.html`
    case 'codeway':
      link.value = 'https://www.codeway.co'
      break
    default:
      link.value = props.customLink
  }
})
</script>

<template>
  <a
    :href="link"
    :target="isDesktop ? '_blank' : '_self'"
    @click="type ? sendEvent({ eventName: `click_${props.type}` }) : ''"
    ><slot></slot
  ></a>
</template>
