<script setup>
import TopBar from '@/components/atoms/TopBar.vue'
import WelcomeWall from '@/components/organisms/WelcomeWall.vue'
import { sendEvent } from '@/helpers/cerebro'
import { sendGTagEvent } from '@/helpers/gtag'
import { onMounted, onBeforeUnmount } from 'vue'

onMounted(() => {
  sendEvent({
    eventName: 'page_displayed',
    properties: {
      page_name: 'landing',
      page_number: 0,
      page_language: 'en',
    },
  })
  sendGTagEvent({
    event: 'page_displayed',
    page_name: 'landing',
    step_number: 0,
  })
})

onBeforeUnmount(() => {
  sendEvent({
    eventName: 'page_completed',
    properties: {
      page_name: 'landing',
      page_number: 0,
      page_language: 'en',
    },
  })
  sendGTagEvent({
    event: 'page_completed',
    page_name: 'landing',
    step_number: 0,
  })
})
</script>

<template>
  <div class="welcome">
    <TopBar :canGoBack="false" />
    <WelcomeWall />
  </div>
</template>

<style scoped>
.welcome {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../assets/images/bg-1920.png');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

@supports (height: 100dvh) {
  .welcome {
    height: 100dvh;
  }
}

@media screen and (max-width: 1440px) {
  .welcome {
    background-image: url('../assets/images/bg-1440.png');
  }
}

@media screen and (max-width: 768px) {
  .welcome {
    background-image: url('../assets/images/bg-tablet.png');
  }
}

@media screen and (max-width: 480px) {
  .welcome {
    background-image: url('../assets/images/bg-mobile.png');
  }
}
</style>
