export const APP_VERSION = '1.0.0'
export const APP_ID = 'com.visionwizard.holybibleweb'
export const SUPPORT_EMAIL = 'support@dummy.com'
export const FAQS = [
  {
    question: 'What is Sparkle?',
    answer:
      'Sparkle is a self-improvement platform that helps you build a better version of yourself. We offer a variety of tools and resources to help you grow and reach your full potential.',
  },
  {
    question: 'How does Sparkle work?',
    answer:
      'Sparkle uses a combination of AI and human coaching to help you set goals, track your progress, and stay motivated. Our platform offers personalized recommendations based on your unique needs and preferences.',
  },
  {
    question: 'What can I expect from Sparkle?',
    answer:
      'With Spark you can expect to receive personalized recommendations, track your progress, and connect with a community of like-minded individuals. Our platform is designed to help you build healthy habits, improve your mindset, and achieve your goals.',
  },
]

export const BACKEND_BASE_URL =
  window.location.href.indexOf('localhost') >= 0 ? 'http://localhost:5050' : ''
