import { getCerebroId } from './cerebro'
import { requestWithIdToken } from './requestWithIdToken'

// Verify payment using Payment Verifier service.
// For detailed architecture, refer to the "Subscription Management Overview" section in the README.

const verifyPayment = async ({ transactionId = null } = {}) => {
  try {
    const verificationResponse = await requestWithIdToken({
      method: 'POST',
      path: `/payment/get-subscriptions`,
      data: {
        cid: getCerebroId(),
        transactionId,
      },
    })
    if (
      verificationResponse.status === 200 &&
      verificationResponse &&
      verificationResponse.data &&
      verificationResponse.data.isActive
    ) {
      return verificationResponse.data
    }
    return null
  } catch (error) {
    console.log('An unexpected error occured while verifying subscription')
    return null
  }
}

export default verifyPayment
