import { discount } from '@/config/products'

export const steps = [
  {
    component: 'SelectOneListEmoji',
    name: 'select_bible',
    id: 'bible',
    props: {
      options: [
        'American Standard Version - ASV',
        'Authorized King James Version - KJV',
        'Bishops Bible',
        'Coverdale Bible - Coverdale',
        'Geneva Bible- Geneva',
        'NET Bible - NET',
        'World English Bible - WEB',
        'Español - RVR',
        'Português - ACF',
      ],
      title: 'Which Bible version do you prefer?',
      emoji: '📖',
    },
  },
  {
    component: 'SelectOneListEmoji',
    name: 'select_bible_read_habits',
    props: {
      options: [
        'I read the Bible daily',
        'I read the Bible a few times a week',
        'I read the Bible occasionally',
        'I am planning to start reading the Bible',
        'I am new to Bible study',
      ],
      title: 'How would you describe your current Bible study habits?',
      emoji: '🤔',
    },
  },
  {
    component: 'SelectOneListEmoji',
    name: 'select_denomination',
    props: {
      options: [
        'Catholic',
        'Protestant',
        'Orthodox',
        'Pentecostal',
        'Nondenominational Protestant',
        'Evangelical',
        'Lutheran',
        'Methodist',
        'Baptist',
        'Adventist',
        'Other',
      ],
      title: 'What is your denomination?',
      emoji: '🌍',
    },
  },
  {
    component: 'SelectOneListEmoji',
    name: 'select_age_range',
    id: 'ageRange',
    props: {
      options: ['13-17', '18-24', '25-34', '35-44', '45-54', '55+'],
      title: 'Select your age ',
      emoji: '🎂',
    },
  },
  {
    component: 'Slider',
    name: 'slide_god_close',
    props: {
      title: 'How close do you feel to God today?',
      emoji: '🙇🏻',
    },
  },
  {
    component: 'SelectOneListEmoji',
    name: 'select_bible_chat_purpose',
    props: {
      options: [
        'Find peace and comfort',
        'Deepen my faith',
        'Study the Bible',
        "Overcome life's challenges",
      ],
      title: 'I want to use Bible Path to...',
      emoji: '🎯',
    },
  },
  {
    component: 'OpenEndedEmoji',
    name: 'input_life_challenge',
    props: {
      title: 'What is the life challenge you are facing?',
      emoji: '🎯',
    },
  },
  {
    component: 'MultiSelectListEmoji',
    name: 'multi_select_spiritual_challenges',
    props: {
      options: [
        'Finding time for reflection and prayer',
        'Connecting with God on a deeper level',
        'Understanding biblical teachings',
        'Managing doubts about faith',
        'Seeking guidance for personal issues',
      ],
      title: 'What spiritual challenges do you face most often?',
      subtext: 'You can select more than one.',
      emoji: '😕',
    },
  },
  {
    component: 'Personalized',
    name: 'final_personalization',
    props: {
      title: 'Customizing your spiritual journey...',
      finalTitle: 'Your personalized spiritual journey is ready!',
      finalButtonText: "I'm ready!",
      items: [
        { title: 'Daily Reflections' },
        { title: 'Interactive Bible Studies' },
        { title: 'Faith Building Exercises' },
        { title: 'Prayer and Meditation Guides' },
      ],
      hasModal: true,
      questions: [
        {
          title:
            'Would you prefer a personalized guide to deeper spiritual insights?',
        },
        { title: 'Ready to gain a clear understanding of the Scriptures?' },
        {
          title:
            'Are you interested in exploring the Bible in a clear and relatable manner?',
        },
        {
          title:
            'Struggling to avoid obstacles that divert you from your faith?',
        },
        {
          title: 'Do you tend to follow through on your commitments?',
        },
      ],
    },
  },
]

export const stepsTest = [
  {
    component: 'OpenEndedEmoji',
    props: {
      title: 'What is the life challenge you are facing?',
      emoji: '🎯',
    },
  },
  {
    component: 'Personalized',
    props: {
      title: 'Customizing your spiritual journey...',
      finalTitle: 'Your personalized spiritual journey is ready!',
      finalButtonText: "I'm ready!",
      items: [
        { title: 'Daily Reflections' },
        { title: 'Interactive Bible Studies' },
        { title: 'Faith Building Exercises' },
        { title: 'Prayer and Meditation Guides' },
      ],
      hasModal: true,
      questions: [
        {
          title:
            'Would you prefer a personalized guide to deeper spiritual insights?',
        },
        { title: 'Ready to gain a clear understanding of the Scriptures?' },
        {
          title:
            'Are you interested in exploring the Bible in a clear and relatable manner?',
        },
        {
          title:
            'Struggling to avoid obstacles that divert you from your faith?',
        },
        {
          title: 'Do you tend to follow through on your commitments?',
        },
      ],
    },
  },
]

export const progressSections = [
  { title: 'About You', start: 0, end: 4 },
  { title: 'Religion & Faith', start: 4, end: 5 },
  { title: 'Purpose', start: 5, end: 8 },
]

export const onboardings = {
  'onboarding-1': {
    steps: steps,
    discountId: discount,
    discountRate: 0.6,
  },
}

export const QUIZ_ID = 'onboarding-1'
