import Layout from '@/views/Layout.vue'
import Welcome from '@/views/Welcome.vue'
import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/store/user'
import { sendEvent } from '@/helpers/cerebro'
import { isStaging } from '@/helpers/environment'
import { auth } from '@/plugins/firebase'

const options = {
  redirectPageAfterLogin: { name: 'Payment' },
  redirectPageAfterLogout: { name: 'Welcome' },
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    { path: '/onboarding', component: Welcome, name: 'Welcome' },
    { path: '/', redirect: { name: 'Welcome' } },
    {
      path: '/',
      component: Layout,
      children: [
        {
          path: 'survey',
          component: () => import('../views/Survey.vue'),
          name: 'Survey',
          meta: { onlyUnauth: true },
        },
        {
          path: 'signup',
          component: () => import('../views/EmailSignUp.vue'),
          name: 'SignUp',
          meta: { onlyUnauth: true },
        },
        {
          path: 'payment-result',
          component: () => import('../views/PaymentResult.vue'),
          name: 'PaymentResult',
          meta: { onlyPro: true },
        },
        ...(isStaging
          ? [
              {
                path: '/settings',
                component: () => import('../views/Settings.vue'),
                name: 'Settings',
                meta: { requiresAuth: true },
              },
            ]
          : []),
      ],
    },
    {
      path: '/payment',
      component: () => import('../views/Payment.vue'),
      name: 'Payment',
      meta: { requiresAuth: true },
    },

    { path: '/:pathMatch(.*)*', redirect: { name: 'Welcome' } },
  ],
})

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore()
  const storeUser = await userStore.getCurrentUser()
  const currentUser = auth.currentUser ?? storeUser

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (currentUser) {
      if (to.matched.some(record => record.meta.onlyPro)) {
        if (userStore.isUserPro()) {
          next()
        } else {
          next(options.redirectPageAfterLogin)
        }
      } else {
        next()
      }
    } else {
      next(options.redirectPageAfterLogout)
    }
  } else if (to.matched.some(record => record.meta.onlyUnauth)) {
    if (currentUser) {
      if (!userStore.isUserPro()) {
        next(options.redirectPageAfterLogin)
      } else {
        next({ name: 'PaymentResult' })
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach((to, from, failure) => {
  if (!failure)
    sendEvent({
      eventName: 'web_page_open',
      page: to.name,
      properties: {
        source: from.name || router.options.history.state.source || '',
        fullPath: to.fullPath,
      },
    })
})

export default router
