import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useUserStore } from '@/store/user'
import { fetchPaddleProducts } from '@/helpers/paddle'
import { sendEvent } from '@/helpers/cerebro'
import { prices } from '@/config/products'

export const useProductStore = defineStore('product', () => {
  const userStore = useUserStore()
  const productsMap = ref({})

  const fetchProductsInternal = async () => {
    await userStore.getCurrentUser()
    const pricingTier = userStore.getParameter('pricing_tier') || 't6'
    const iap_catalog = JSON.parse(
      userStore.getParameter('iap_catalog') || '{}',
    )
    const paywallStructure = JSON.parse(
      userStore.getParameter('paywall_structure') || '{}',
    )
    const iapCatologProducts =
      iap_catalog && iap_catalog.paddle && iap_catalog.paddle.pro
        ? iap_catalog.paddle.pro
        : []
    try {
      sendEvent({
        eventName: 'loadProducts',
      })
      if (Object.keys(paywallStructure).length > 0) {
        let failedFetching = false
        for (let source in paywallStructure) {
          let fetchingProductsIds = paywallStructure[source].products.map(
            productKey => {
              if (
                iapCatologProducts[productKey] &&
                iapCatologProducts[productKey][pricingTier]
              ) {
                return {
                  quantity: 1,
                  priceId: iapCatologProducts[productKey][pricingTier],
                }
              } else {
                failedFetching = true
                return { quantity: 1, priceId: '' }
              }
            },
          )

          if (failedFetching) {
            failedFetching = false
            continue
          }
          console.log(
            'Fetching products for source:',
            source,
            fetchingProductsIds,
          )
          const paddleResponse = await fetchPaddleProducts(fetchingProductsIds)
          productsMap.value[source] = paddleResponse
        }
        if (
          productsMap.value['default'] === null ||
          productsMap.value['default'].length === 0
        ) {
          console.log(
            'Fetching default products',
            productsMap.value['default'] === null,
            productsMap.value['default'].length === 0,
          )
          throw Error
        }
      } else {
        throw Error
      }
    } catch (error) {
      console.log(error)
      fetchDefaultProducts()
    }
  }

  const fetchDefaultProducts = async () => {
    // If somehow could not fetch config
    try {
      const paddleResponse = await fetchPaddleProducts(prices)
      productsMap.value['default'] = paddleResponse
    } catch (error) {
      console.log(error)
      productsMap.value['default'] = []
    }
  }

  const getProductsWithSource = source => {
    if (
      productsMap.value[source] !== undefined &&
      productsMap.value[source].length !== 0
    ) {
      return productsMap.value[source]
    }
    return productsMap.value['default'] ?? []
  }

  const refetchProducts = async type => {
    if (type === 'default') {
      await fetchDefaultProducts()
    } else {
      await fetchProductsInternal()
    }
  }

  return {
    productsMap,
    getProductsWithSource,
    refetchProducts,
  }
})
