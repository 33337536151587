import { getCerebroId } from './cerebro'
import { Base64, sha256 } from './crypto'

const generateSessionId = async () => {
  const cerebroId = getCerebroId()
  return await sha256(Base64.fromNumber(Date.now()) + cerebroId)
}

export const getSessionId = async () => {
  let sid = sessionStorage.getItem('cdw_sid')
  if (!sid || sid == 'undefined') {
    sid = await generateSessionId()
    sessionStorage.setItem('cdw_sid', sid)
  }
  return sid
}
