// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { initializeAuth, setPersistence, browserLocalPersistence, browserSessionPersistence, debugErrorMap } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { firebaseConfig } from '@/config/firebaseConfig'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase
const app = initializeApp(firebaseConfig)

//initialize firebase auth
const auth = initializeAuth(app, {
  errorMap: debugErrorMap, // Enable verbose error messages
  persistence: [
    browserLocalPersistence, // Try local persistence first
    browserSessionPersistence // Fall back to session persistence
  ]
})

const db = getFirestore(app)

export { app, auth, db }
