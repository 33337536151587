import { APP_VERSION, APP_ID } from '@/constants/general'
import axios from 'axios'
import CryptoJS from 'crypto-js'
import { Base64 } from './crypto'
import { getPage } from './page'
import { getSessionId } from './sessionManager'
import { useUserStore } from '../store/user'
import { isStaging } from '@/helpers/environment'

const cerebroBaseUrl = import.meta.env.VITE_CEREBRO_BASE_URL
const configBaseUrl = import.meta.env.VITE_CONFIG_BASE_URL
const eventPath = '/log/web'
const userPath = '/user/web'
const apiKey = import.meta.env.VITE_CEREBRO_API_KEY
const passphrase = import.meta.env.VITE_CEREBRO_PASSPHRASE

// There are basically three sources: memory, local storage, user database
export const getCerebroId = () => {
  const userStore = useUserStore()
  // check memory
  if (userStore.cerebroId) {
    localStorage.setItem('cdw_cid', userStore.cerebroId)
    return userStore.cerebroId
  }

  // check user database
  if (userStore.userData && userStore.userData.cerebroId) {
    const cid = userStore.userData.cerebroId
    userStore.setCerebroId(cid)
    return cid
  }

  // check local storage
  const localStorageCid = localStorage.getItem('cdw_cid')
  if (localStorageCid) {
    userStore.setCerebroId(localStorageCid)
    return localStorageCid
  }

  const cid = generateCerebroId()
  localStorage.setItem('cdw_cid', cid)
  userStore.setCerebroId(cid)
  return cid
}

const getOSName = () => {
  let OSName = 'Unknown OS'
  if (navigator.userAgent.indexOf('Win') != -1) OSName = 'Windows'
  if (navigator.userAgent.indexOf('Mac') != -1) OSName = 'Mac OS'
  if (navigator.userAgent.indexOf('Linux') != -1) OSName = 'Linux'
  if (navigator.userAgent.indexOf('Android') != -1) OSName = 'Android'
  if (navigator.userAgent.indexOf('like Mac') != -1) OSName = 'iOS'
  return OSName
}

const getBrowserName = () => {
  const userAgent = navigator.userAgent

  const browsers = [
    { name: 'Edge', regex: /Edg\// },
    { name: 'Opera', regex: /OPR\// },
    { name: 'Vivaldi', regex: /Vivaldi/ },
    { name: 'Chrome', regex: /Chrome\// },
    { name: 'Firefox', regex: /Firefox\// },
    { name: 'Safari', regex: /Version\/.*Safari/ },
    { name: 'Internet Explorer', regex: /Trident\/|MSIE/ },
  ]

  for (let browser of browsers) {
    if (browser.regex.test(userAgent)) {
      return browser.name
    }
  }

  return 'Unknown'
}

const generateCerebroId = () => {
  const now = Date.now()
  const randomNumber = Math.floor(Math.random() * 258047) + 4096
  return 'web_' + Base64.fromNumber(randomNumber) + Base64.fromNumber(now)
}

const formatProperties = props => {
  if (props == undefined || Object.keys(props).length == 0) {
    return []
  }
  const result = []
  Object.keys(props).forEach(key => {
    let value = props[key]
    let valueKey = 'string_value'
    if (typeof value == 'number') {
      if (value % 1 === 0) {
        valueKey = 'int_value'
      } else {
        valueKey = 'float_value'
      }
    } else {
      value = String(value)
    }
    result.push({
      key,
      value: {
        [valueKey]: value,
      },
    })
  })
  return result
}

export const sendEvent = async ({
  eventName,
  eventGroup,
  duration,
  page,
  properties,
  result,
}) => {
  properties = addDeviceProperties(properties)
  const eventBody = {
    user_id: getCerebroId(),
    type: 'event',
    app_id: APP_ID,
    version: APP_VERSION,
    result: result || 'success',
    session_id: await getSessionId(),
    event_group: eventGroup || null,
    event_name: eventName,
    event_time: Date.now(),
    duration: duration || null,
    page: page || getPage(),
    properties: formatProperties(properties),
  }
  const requestBody = {
    event_logs: [eventBody],
  }
  const ts = Date.now()
  const signatureString = [
    JSON.stringify(requestBody),
    apiKey,
    ts,
    eventPath,
  ].join('_')
  const rs = createRS(signatureString)

  if (isStaging) {
    // console.log(`Sending event ${eventName}`, requestBody)
  }

  axios.post(cerebroBaseUrl + eventPath, requestBody, {
    headers: {
      authorization: apiKey,
      ts,
      rs,
    },
  })
}

const createRS = str => {
  return CryptoJS.HmacSHA256(str, passphrase).toString(CryptoJS.enc.Hex)
}

export const sendUserEvent = async data => {
  if (!data) {
    data = {}
  }
  if (!data.user_id) {
    data.user_id = getCerebroId()
  }
  data.operating_system = getOSName()
  const trackerName = getTrackerName()
  if (trackerName) {
    data.tracker_name = trackerName
    writeToUserStore(trackerName, 'setTrackerName')
  }
  data.properties = { userAgent: navigator.userAgent }
  if (window.cerebroUserEvents == undefined) {
    window.cerebroUserEvents = []
  }
  window.cerebroUserEvents.push(data)
  triggerCerebroUserEventSender()
  sendAttributionUserEvent(data)
}

const sendAttributionUserEvent = data => {
  if (!data) return
  Object.assign(data, data.properties)
  delete data.properties
  sendEvent({ eventName: 'user_attribution', properties: data })
}

const triggerCerebroUserEventSender = async () => {
  if (
    window.cerebroUserEventSender == undefined ||
    window.cerebroEventSenderStatus == 'idle'
  ) {
    window.cerebroEventSenderStatus = 'working'
    const events = window.cerebroUserEvents
    if (events) {
      const userId = events[events.length - 1].user_id
      while (events.length > 0) {
        const data = events.shift()
        try {
          if (isStaging) {
            // console.log("sending user event", data)
          }
          await sendUserDataToCerebro(data)
        } catch (error) {
          console.log(error)
          events.push(data)
        }
      }
      // if (userId) {
      //   await getConfig(userId)
      // }
    }
    window.cerebroEventSenderStatus = 'idle'
  }
}

const sendUserDataToCerebro = async data => {
  const requestBody = {
    user_id: data.user_id || getCerebroId(),
    app_id: APP_ID,
    version: APP_VERSION,
    ...data,
  }

  const ts = Date.now()
  const signatureString = [
    JSON.stringify(requestBody),
    apiKey,
    ts,
    userPath,
  ].join('_')
  const rs = createRS(signatureString)
  await axios.post(cerebroBaseUrl + userPath, requestBody, {
    headers: {
      authorization: apiKey,
      ts,
      rs,
    },
  })
}

export const getTrackerName = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const utmSource = urlParams.get('utm_source')
  const utmMedium = urlParams.get('utm_medium')
  const utmCampaign = urlParams.get('utm_campaign')
  const utmTerm = urlParams.get('utm_term')
  const utmContent = urlParams.get('utm_content')
  const trackerNameArr = []
  let platformStr = ''
  if (utmSource || utmMedium) {
    if (utmSource) {
      if (utmMedium) {
        platformStr = `${utmSource}_${utmMedium}`
      } else {
        platformStr = utmSource
      }
    } else if (utmMedium) {
      platformStr = utmMedium
    }
  }
  trackerNameArr.push(platformStr)
  if (utmCampaign) {
    trackerNameArr.push(utmCampaign)
  }
  if (utmContent) {
    trackerNameArr.push(utmContent)
  }
  if (utmTerm) {
    trackerNameArr.push(utmTerm)
  }
  return trackerNameArr.join('::')
}

const getConfig = async userId => {
  const response = await axios.get(configBaseUrl + '/web', {
    params: {
      aid: APP_ID,
      id: userId,
      version: APP_VERSION,
      language: navigator.language.split('-')[0],
    },
  })
  if (
    response &&
    response.data &&
    response.data.status &&
    response.data.status.code == 200
  ) {
    writeToUserStore(response.data.payload, 'setConfig')
    sendEvent({ eventName: 'config_result', result: '1' })
  } else {
    writeToUserStore({ configFailed: true }, 'setConfig')
    sendEvent({ eventName: 'config_result', result: '0' })
  }
}

const writeToUserStore = (data, method) => {
  const userStore = useUserStore()
  userStore[method](data)
}

const addDeviceProperties = properties => {
  if (!properties) {
    properties = {}
  }
  const os = getOSName()
  properties.platform = os === 'iOS' || os === 'Android' ? 'mobile' : 'desktop'
  properties.operating_system = os
  properties.screen_size = `${screen.width} x ${screen.height}`
  properties.viewport_size = `${window.innerWidth} x ${window.innerHeight}`
  properties.dpr = window.devicePixelRatio
  properties.browser = getBrowserName()
  properties.browser_language = navigator.language
  properties.referrer = document.referrer
  properties.is_direct = !document.referrer
  return properties
}
