import { sendGTagEvent } from '../helpers/gtag'
import { sha256 } from '../helpers/crypto'
import { getCerebroId, sendEvent, sendUserEvent } from '../helpers/cerebro'
import { useUserStore } from '@/store/user'
import { initializePaddle } from '@/helpers/paddle'
import { requestWithIdToken } from '@/helpers/requestWithIdToken'
import verifyPayment from '@/helpers/paymentVerification'
import * as Sentry from '@sentry/vue'

const providerToName = {
  password: 'mail',
  'google.com': 'google',
  'facebook.com': 'facebook',
  'apple.com': 'apple',
}

export const setUserData = async userData => {
  return await requestWithIdToken({
    method: 'POST',
    path: '/user/set',
    data: userData,
  })
}

export const getUserData = async uid => {
  return await requestWithIdToken({
    method: 'POST',
    path: '/user/get',
    data: { userId: uid },
  })
}

export const deleteUserData = async () => {
  return await requestWithIdToken({
    method: 'DELETE',
    path: '/user',
  })
}

export const setUserClickIds = async userClickIds => {
  return await requestWithIdToken({
    method: 'POST',
    path: '/user/set-click-ids',
    data: userClickIds,
  })
}

// Steps to perform upon login
export const performUserLoginActions = async (
  authUser,
  dataToSet,
  isJustRefetch,
  source,
) => {
  // Add email of user to the dataLayer
  const mailHash = await sha256(authUser.email)
  sendGTagEvent({
    event: 'email_collected',
    em: mailHash,
    fn: mailHash,
    ln: mailHash,
  })

  if (authUser.providerData[0].providerId === 'password') {
    sendEvent({
      eventName: 'sign_in',
      eventGroup: 'authentication',
    })
    sendGTagEvent({ event: 'sign_in', user_id: authUser.uid })
  }

  // Get user data from db
  const userDataResponse = await getUserData(authUser.uid)
  const userDataPayload =
    userDataResponse && userDataResponse.data && userDataResponse.data.payload
      ? userDataResponse.data.payload
      : undefined
  // If cerebroId is not received from backend, check stores, local storage and if doesn't exist create one
  if (userDataPayload && !userDataPayload.webCerebroId) {
    dataToSet.cerebroId = getCerebroId()
  } else if (userDataPayload && userDataPayload.cerebroId) {
    const userStore = useUserStore()
    userStore.setCerebroId(userDataPayload.cerebroId)
  }
  if (userDataPayload) {
    sendEvent({ eventName: 'user_data_result' })
    try {
      const subscriptionResponse = await verifyPayment()
      if (subscriptionResponse) {
        userDataPayload.subscriptions = subscriptionResponse
      } else {
        userDataPayload.subscriptions = null
      }
    } catch (error) {
      console.log('An error occured getting subscriptions')
    }
  }
  if (!isJustRefetch) {
    // send user event to cerebro
    const cerebroUserData = {
      user_id: getCerebroId(),
      account_id: authUser.uid,
    }
    sendUserEvent(cerebroUserData)
    // set user data to db
    setUserData(dataToSet)
    Sentry.setUser({
      email: authUser.email,
      userId: authUser.uid,
      cerebroId: getCerebroId(),
    })
    // if user has paddleCustomerId reinitialize paddle
    if (userDataPayload && userDataPayload.paddleCustomerId) {
      initializePaddle(userDataPayload.paddleCustomerId)
    }
  }
  return userDataPayload
}

export const performUserSignupActions = authUser => {
  const clickIds = getClickIds()

  if (Object.keys(clickIds).length !== 0) {
    setUserClickIds({
      webId: 'com.visionwizard.holybibleweb',
      userId: authUser.uid,
      clickIds: {
        email: authUser.email,
        ...clickIds,
      },
    })
  }
  // send signup event to gtag
  sendGTagEvent({ event: 'sign_up', user_id: authUser.uid })

  // send event to cerebro
  sendEvent({
    eventName: 'sign_up',
    eventGroup: 'authentication',
  })
}

const getClickIds = () => {
  const data = {
    gclid: getCookie('_p2s_gcl') || localStorage.getItem('_p2s_gcl'),
    fbc: getCookie('_fbc') || localStorage.getItem('_fbc'),
    fbp: getCookie('_fbp') || localStorage.getItem('_fbp'),
    ttclid: getCookie('ttclid') || localStorage.getItem('ttclid'),
    ttp: getCookie('_ttp') || localStorage.getItem('_ttp'),
  }

  return Object.fromEntries(
    Object.entries(data).filter(([_, value]) => value != null),
  )
}

const getCookie = name => {
  const nameEQ = name + '='
  const cookies = document.cookie.split(';')
  for (let cookie of cookies) {
    cookie = cookie.trim() // Remove leading spaces
    if (cookie.startsWith(nameEQ)) {
      return cookie.substring(nameEQ.length)
    }
  }
  return null
}
