import { isStaging } from '@/helpers/environment'

export const prices = isStaging
  ? [
      {
        quantity: 1,
        priceId: 'pri_01jd7jsw2pn86axr9aqfnf64s1',
      },
      {
        quantity: 1,
        priceId: 'pri_01jc0yahtyv30bwrk082b7n03n',
      },
    ]
  : [
      { quantity: 1, priceId: 'pri_01jdhv5eg37fh5wpdc94z02bzy' },
      { quantity: 1, priceId: 'pri_01jdhv7980wc5bgn4bn938w84d' },
    ]

export const discount = isStaging
  ? 'dsc_01jc5fcrm4s04fkdev9ppaj1y0'
  : 'dsc_01jdhv85b093pc2nk9fc5tbsdy'
