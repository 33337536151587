<script setup>
import { onAuthStateChanged } from '@firebase/auth'
import { useUserStore } from '@/store/user.js'
import { useRouter } from 'vue-router'
import { isStaging } from './helpers/environment'
import { initializePaddle } from './helpers/paddle.js'
import { onMounted } from 'vue'
import { sendGTagEvent } from './helpers/gtag'
import { sendEvent } from './helpers/cerebro'
import { QUIZ_ID } from './constants/onboarding'
import { auth } from '@/plugins/firebase'


const router = useRouter()

onMounted(() => {
  initializePaddle()
  const userStore = useUserStore()
  sendGTagEvent({
    event: 'flow_started',
  })
  sendEvent({
    eventName: 'onboarding_started',
    properties: {
      quiz_id: QUIZ_ID,
    },
  })
  onAuthStateChanged(auth, async user => {
    if (isStaging) {
      //console.log('::USER::', user)
    }
    if (user) {
      localStorage.setItem('iuli', true)
      userStore.setUser(user, false, router.options.history.state.current)
    } else {
      localStorage.removeItem('iuli')
      userStore.setUser(null)
    }
  })
})
</script>

<template>
  <RouterView />
</template>
